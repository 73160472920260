import { device } from 'src/styles/breakpoints'
import { orange, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`
  h2 {
    font-family: 'Citrina', Helvetica, sans-serif;
  }

  .play {
    position: absolute;
    top: 50%;
    display: flex;
    left: 36%;

    @media ${device.desktopLG} {
      left: 40%;
    }
  }
`

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${white};
  background: ${orange.extra};
  width: 100%;
  height: 48px;
  margin-top: 30px;

  &:focus { 
    outline: none; 
  } 
  
  @media ${device.tablet} {
    width: 216px;
  }

  @media ${device.desktopLG} {
    width: 220px;
  }

  @media ${device.desktopXL} {
    width: 265px;
    margin-top: 40px;
  }
`

export const LinkMobile = styled.a`
  width: 100%;
  height: 48px;
  background: #FF7A00;
  border-radius: 8px;
  color: #fff;
  font-weight: bold;
  margin-top: 17px;
  max-width: 497px;
  border: none;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus, &:hover {
    outline: none;
    color: #fff;
    opacity: 0.9;
  }

  @media (min-width: ${device.desktopLG}) {
    margin-top: 33px;
  }
  @media (min-width: ${device.desktopXL}) {
    margin-top: 27px;
  }
`
