import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import { primary } from 'src/styles/newColors'
import styled from 'styled-components'

export const Section = styled.section`

  h2 {
    font-family: 'Citrina', Helvetica, sans-serif;
  }

  .ler-mais {
    font-size: 14px;
    line-height: 17px;

    @media ${device.tablet} {
      font-size: 14px;
      line-height: 17px;
    }
  }

  .btn-artigos {
    text-transform: initial;
    border: 1px solid ${primary[300]};
    ${primary[300]};
    width: 214px;

    &.active, &:hover {
      color: ${primary[300]};
    }
  }
`

export const Card = styled.div`
  .primary-tag, .secundary-tag {
    display: inline;
    width: max-content;
  }
  border-bottom: 1px solid ${grayscale[200]};
  
  @media ${device.tablet} {
    border: 1px solid ${grayscale[200]};
    border-radius: 18px;
  }

  h3 {
    @media ${device.tablet} {
      height: 60px;
    } 
  }
`
