import { device } from 'src/styles/breakpoints'
import { orange, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`
  h2 {
    font-family: 'Citrina', Helvetica, sans-serif;
  }

  .react-multi-carousel-dot-list {
    bottom: 188px;
    padding: 0 0 0 192px;
    width: 40px;
    
    @media ${device.tablet} {
      bottom: 155px;
      padding: 0 0 0 220px;
    }

    @media ${device.desktopLG} {
      bottom: 150px;
      padding: 0 0 0 224px;
    }

    @media ${device.desktopXL} {
      bottom: 122px;
      padding: 0 0 6px 258px;
    }
    
    @media ${device.desktopXXXL} {
      bottom: 128px;
      padding: 0 0 0 258px;
    }
    
    li {
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      cursor: pointer;

      @media ${device.desktopLG} {
        width: auto;
        height: auto;
        svg {
          width: 24px;
          height: 24px;
        }
      }

      @media ${device.desktopXL} {
        width: auto;
        height: auto;
        svg {
          width: 32px;
          height: 32px;
        }
      }
    }
  }

  .react-multiple-carousel__arrow--left {
    left: -6px 
  }

  .react-multiple-carousel__arrow--right {
    right: -5px 
  }

  .react-multiple-carousel__arrow {
    @media ${device.desktopXL} {
      bottom: -10px;
    }
  }

  .carousel-content {
    top: 40px;
    position: relative;

    @media ${device.desktopXL} {
      top: 30px;
    }
  }
`

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${white};
  background: ${orange.extra};
  width: 100%;
  height: 48px;

  &:focus { 
    outline: none; 
  }
  
  @media ${device.tablet} {
    width: 216px;
    margin-top: 10px;
  }

  @media ${device.desktopXL} {
    width: 264px;
    margin-top: 35px;
  }
`

export const LinkMobile = styled.a`
  width: 100%;
  height: 48px;
  background: #FF7A00;
  border-radius: 8px;
  color: #fff;
  font-weight: bold;
  margin-top: 17px;
  max-width: 497px;
  border: none;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus, &:hover {
    outline: none;
    color: #fff;
    opacity: 0.9;
  }

  @media (min-width: ${device.desktopLG}) {
    margin-top: 33px;
  }
  @media (min-width: ${device.desktopXL}) {
    margin-top: 27px;
  }
`
