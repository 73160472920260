import React from 'react'
import ImageWebp from 'src/components/ImageWebp'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import IntercoOrangeIcon from 'src/components/UI/MarkdownIcon/InterCoIcons'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'

import { Button, LinkMobile, Section } from './style'

import BenefitsJSON from '../../assets/data/benefits.json'

type IModalDataLayerProps = {
  sendDatalayerEvent: Function;
  setIsOpen: Function;
  setDataLayer: Function;
  isMobile: boolean;
  oneLink: string;
}

type CarouselDot = {
  onClick: Function;
  active: string;
  index: number;
}

type BenefitsProps = {
  title: string;
  description: string;
}

const Benefits = ({ setIsOpen, sendDatalayerEvent, setDataLayer, isMobile, oneLink }: IModalDataLayerProps) => {
  const icons = BenefitsJSON
  const windowWidth = useWidth(200)

  const CustomDot = ({ onClick, active, index }: CarouselDot) => {
    return (
      <li
        className={active
        ? 'bg-orange--base rounded-50 mx-0 mx-md-1 mx-lg-0 px-3 py-3'
        : 'mx-0 mx-md-1 mx-lg-0 px-3 py-3'}
        onClick={() => onClick()}
      >
        <IntercoOrangeIcon
          icon={icons[index].icon}
          size='MD'
          color={active ? '#FFFFFF' : '#000000' }
        />
      </li>
    )
  }

  const dobra03: IDataLayerParams = {
    section: 'dobra_03',
    section_name: 'Vantagens para quem investe em COE',
    element_action: 'click button',
    element_name: 'Reservar agora',
  }

  const handleButtonClick = () => {
    setIsOpen(true)
    setDataLayer(dobra03)
    sendDatalayerEvent(dobra03)
  }

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-0'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-28 lh-lg-38 lh-xl-48 text-grayscale--500 fw-700'>
              Vantagens para quem investe
            </h2>
            <DefaultCarousel
              sm={{ items: 1 }}
              md={{ items: 1 }}
              lg={{ items: 1 }}
              xl={{ items: 1 }}
              customDots={CustomDot}
              containerClass='mt-xl-5'
            >
              {
                BenefitsJSON.map((item: BenefitsProps) => (
                  <div
                    key={item.title}
                    className='justify-content-between justify-content-md-start d-flex flex-column carousel-content'
                  >
                    <h3 className='fs-16 lh-20 text-grayscale--500 fw-600 mt-lg-3 mt-xl-2 mb-lg-2'>{item.title}</h3>
                    <p className='fs-16 lh-20 text-grayscale--400'>{item.description}</p>
                  </div>
                ))
              }
            </DefaultCarousel>
            {
              isMobile ? (
                <LinkMobile
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_03',
                      element_action: 'click button',
                      element_name: 'Reservar agora',
                      section_name: 'Vantagens para quem investe',
                      redirect_url: oneLink,
                    })
                  }}
                  href={oneLink}
                >Reservar agora
                </LinkMobile>
              ) : (
                <Button
                  onClick={handleButtonClick}
                  title='Reservar agora'
                  className='fs-14 lh-17 fw-600 text-orange--extra rounded-2 border-0 text-none'
                >
                  Reservar agora
                </Button>
            )
          }
          </div>
          {windowWidth > WIDTH_MD &&
            <div className='col-lg-4 offset-lg-1'>
              <ImageWebp
                arrayNumbers={[ 324, 696, 372, 455 ]}
                pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/grafismo1-coe/image.webp'
                altDescription='Pessoa com roupa laranja e branca olhando para o smartphone as opções de operações estruturadas do Inter.'
              />
            </div>}
        </div>
      </div>
    </Section>
  )
}

export default Benefits
