import React, { useLayoutEffect, useState } from 'react'
import { Modal } from 'src/components/Modal'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import useDomReady from 'src/hooks/window/useDomReady'
import useWidth from 'src/hooks/window/useWidth'
import Layout from 'src/layouts/BaseLayout'

import Attention from './sections/attention/_index'
import Benefits from './sections/benefits/_index'
import Disclaimer from './sections/disclaimer/_index'
import Hero from './sections/hero/_index'
import Invest from './sections/invest/_index'
import NominatedToCOE from './sections/nominated-to-coe/_index'
import TheBestContent from './sections/the-best-content/_index'
import TypesOfInvestments from './sections/types-of-investments/_index'
import UnderstandMore from './sections/understand-more/_index'

import FAQ from './sections/faq/_index'

import pageContext from './pageContext.json'

import QrCode from './assets/images/qr-code-coe.png'
import { Wrapper } from './style'

const WIDTH_MD = 768

const COE = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ isOpen, setIsOpen ] = useState(false)
  const domReady = useDomReady()
  const [ isMobile, setIsMobile ] = useState(true)
  const width = useWidth(300)
  const oneLink = 'https://inter-co.onelink.me/Qyu7/hq5et19h'

  const dobra01: IDataLayerParams = {
    section: 'dobra_01',
    section_name: 'COE - Certificado de Operações Estruturadas',
    element_action: 'click button',
    element_name: '',
  }

  const [ dataLayer, setDataLayer ] = useState<IDataLayerParams>(dobra01)

  const hubModal = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen}
        setIsModal={setIsOpen}
        title='<span class="fs-20 lh-25 fs-md-24 lh-md-30 text-grayscale--500">Escaneie o QR Code,  acesse o app e faça seu investimento.</span>'
        instructions='Ligue a câmera do seu celular e aponte para o QR Code acima. Você será redirecionado para a seção com os tipos de COE disponíveis em nosso app.'
        openAccount='<span class="text-grayscale--500">Não tem sua Conta Digital ainda?</span>'
        openAccountModal={true}
        isHrBottom
        qrCode={QrCode}
        dataLayer={dataLayer}
      />
    </Modal>
  )

  useLayoutEffect(() => {
    setIsMobile(width < WIDTH_MD)
  }, [ width ])

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {hubModal}
        <Hero
          sendDatalayerEvent={sendDatalayerEvent}
          setIsOpen={setIsOpen}
          setDataLayer={setDataLayer}
          dobra01={dobra01}
          isMobile={isMobile}
          oneLink={oneLink}
        />
        <NominatedToCOE
          sendDatalayerEvent={sendDatalayerEvent}
          setIsOpen={setIsOpen}
          setDataLayer={setDataLayer}
          isMobile={isMobile}
          oneLink={oneLink}
        />
        <Benefits
          sendDatalayerEvent={sendDatalayerEvent}
          setIsOpen={setIsOpen}
          setDataLayer={setDataLayer}
          isMobile={isMobile}
          oneLink={oneLink}
        />
        <TypesOfInvestments
          sendDatalayerEvent={sendDatalayerEvent}
          setIsOpen={setIsOpen}
          setDataLayer={setDataLayer}
          isMobile={isMobile}
          oneLink={oneLink}
        />
        <Invest
          sendDatalayerEvent={sendDatalayerEvent}
          setIsOpen={setIsOpen}
          setDataLayer={setDataLayer}
          isMobile={isMobile}
          oneLink={oneLink}
        />
        <UnderstandMore
          sendDatalayerEvent={sendDatalayerEvent}
          setIsOpen={setIsOpen}
          setDataLayer={setDataLayer}
          isMobile={isMobile}
          oneLink={oneLink}
        />
        <TheBestContent
          sendDatalayerEvent={sendDatalayerEvent}
        />
        <FAQ faq={pageContext.structuredData.faq} />
        <Attention />
        <Disclaimer
          sendDatalayerEvent={sendDatalayerEvent}
        />
      </Layout>
    </Wrapper>
  )
}

export default COE
