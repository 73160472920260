import { device } from 'src/styles/breakpoints'
import { gray, grayscale, orange, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`

  h2 {
    font-family: 'Citrina', Helvetica, sans-serif;
  }

  h3 {
    @media ${device.desktopXL} {
      letter-spacing: -1px;
    }
  }

  .react-multi-carousel-dot-list {
    li {
      width: 48px;
      height: 48px;
      padding: 14px 0 0 0;
      border-radius: 4px;
    }
  }
`

export const Investment = styled.div`
  border: 1px solid ${grayscale[200]};
  border-radius: 10px;
`

export const HeaderInvestment = styled.div`
  border-bottom: 1px solid ${gray[500]};
  
  @media ${device.tablet} {
    min-height: 93px;
  }

  @media ${device.desktopLG} {
    min-height: 125px;
  }

  @media ${device.desktopXL} {
    min-height: 110px;
  }
`

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${white};
  background: ${orange.extra};
  width: 100%;
  height: 48px;

  &:focus { 
    outline: none; 
  }
  
  @media ${device.tablet} {
    width: 216px;
    margin-top: 10px;
  }

  @media ${device.desktopLG} {
    width: 212px;
    margin-top: 12px;
  }

  @media ${device.desktopXL} {
    width: 265px;
    margin-top: 35px;
  }
`

export const LinkMobile = styled.a`
  height: 48px;
  color: ${orange.extra};
  font-weight: bold;
  margin-top: 17px;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  border: none;

  &:focus, &:hover {
    outline: none;
    color: ${orange.extra};
    border: none;
  }

  @media (min-width: ${device.desktopLG}) {
    margin-top: 33px;
  }
  @media (min-width: ${device.desktopXL}) {
    margin-top: 27px;
  }
`
